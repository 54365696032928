.inputGroup-radio {
  background-color: transparent;
  display: block;
  margin: 10px 0;
  position: relative;

  .label-radiobutton {
    padding: 12px 30px;
    width: 100%;
    display: block;
    text-align: left;
    color: #3c454c;
    cursor: pointer;
    position: relative;
    z-index: 2;
    transition: color 200ms ease-in;
    overflow: hidden;
    border-color: #dee2e6;
    border-width: 1px;
    border-style: solid;

    &:before {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      content: "";
      background-color: #000;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) scale3d(1, 1, 1);
      transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
      opacity: 0;
      z-index: -1;
    }

    &:after {
      width: 32px;
      height: 32px;
      content: "";
      border: 2px solid #d1d7dc;
      background-color: #fff;
      background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E ");
      background-repeat: no-repeat;
      background-position: 2px 3px;
      border-radius: 50%;
      z-index: 2;
      position: absolute;
      right: 30px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      transition: all 200ms ease-in;
    }
  }

  .input-radio-button:checked ~ label {
    color: #fff;

    &:before {
      transform: translate(-50%, -50%) scale3d(56, 56, 1);
      opacity: 1;
    }

    &:after {
      background-color: #28a745;
      border-color: #28a745;
    }
  }

  .input-radio-button {
    width: 32px;
    height: 32px;
    order: 1;
    z-index: 2;
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    visibility: hidden;
  }
}
.packing-question {
  padding-left: 0px !important;
  padding-right: 0px !important;
  p {
    text-align: start;
    i {
      color: #000;
      height: 36px;
      width: 36px;
    }
  }
}

.delivery-options {
  display: flex;
  justify-content: space-between;
}

.delivery-buttons {
  border: 1px solid;
  cursor: pointer;
  text-align: left;
  display: flex;
  border-radius: 4px;
}

.delivery-buttons.active {
  border-color: black;
  background-color: black;
  color: white;
  font-weight: bold;
  img {
    filter: brightness(0) invert(1);
  }
  border-radius: 4px;
}

.delivery-buttons.active .content {
  color: black;
  background-color: white;
  padding: 18px 16px;
}

.delivery-buttons.deactive {    
  border-color: #ddd;
  background-color: #DDDDDD;
  color: #8F90A6;
  img {
    opacity: 0.5;
  }
}

.delivery-buttons.deactive .content {
  color: #707070;
  background-color: white;
  padding: 18px 16px;
}

.packagingInfo {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.selectedStore {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 10px;
}
.cart-ico{
  font-size: 1.8rem;
}

.underline-hover {
  color: #000;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  text-align: left;
  text-decoration: none;
}

.underline-hover:hover {
  color: #000;
  text-decoration: underline;
}

a {
  color: #000;
  text-decoration: none;
}

a:hover {
  color: #433f75;
  text-decoration: underline;
}
.div-count-cart{
  width: 30px;
  height: 30px;
  background: #000;
  display: flex;
  align-items: center;
  justify-content: center
}
.text-primary{
  color: #000 !important;
}
.divider {
  border: 1px solid #f1f1f1;
  width: 0px;
  height: 33px;
}
.step-number {
  font-size:22px;
  position:relative;
  font-weight: bolder;
}
.step-number:after {
  content:'';
  position:absolute;
  width:1.5px;
  height: 70%;
  background-color:white;
  right: -6px;
  top:30%;
  transform: rotate(40deg);
}

.stepper-title {
  font-style: normal;
  font-variant : normal;
  font-weight: 800 !important;
  font-size: 16px !important;
}
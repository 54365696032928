.profile-container {
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 10px;
}

.profile-container img {
  width: 99px;
}
.profile-selected {
  border-bottom: 2px solid #000;
}
.selected-left {
  border-left: 5px solid #000 !important;
  border-bottom: 5px solid #000 !important;
  border-top: 5px solid #000 !important;
}

.selected-top {
  border-left: 5px solid #000 !important;
  border-right: 5px solid #000 !important;
  border-top: 5px solid #000 !important;
}

.selected-remain {
  border-left: 5px solid #000 !important;
  border-top: 5px solid #000 !important;
}

.stock-alert{
  font-size: 12px;
  color: #dc3545;
}

.hover {
  position: relative;

  &:hover &__no-hover {
    opacity: 0;
  }

  &:hover &__hover {
    opacity: 1;
  }

  &__hover {
    position: absolute;
    top: 0;
    left:0;
    opacity: 0;
  }

  &__no-hover {
    opacity: 1;
  }
}

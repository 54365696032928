.finished {
  .btn-group-lg > .btn,
  .btn-lg {
    line-height: 2.5rem;
    font-size: 18px;
  }
  .footer-options {
    margin-top: 1rem;
    text-align: center;
  }
}
a:hover {
  text-decoration: none;
}
.btn {
  font-size: 18px;
}
.image-background {
  background: #7b7b7b;
}

.hbt-service-button {
  background-color: #000;
  font-weight: bold;
  padding: 0.5rem;
  border-radius: 3px;
  z-index: 1;
  position: relative;
  color: white !important;
}

.btn-primary-checkout {
  padding: 0.5rem 1rem;
  margin: 0.25rem;
  background-color: #000;
  color: white !important;
}

h6{
  color: #000;
  font-size: 1.2rem;
  line-height: 2rem;
}

h5{
  color: #000;
  font-size: 1.5rem;
}

.solution-link {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  img {
    height: 340px;
    width: 100%;
  }
  span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: #000;
    border-radius: 0.4rem;
    font-weight: bold;
    font-size: 1.6rem !important;
    width: 64%;
    color: #000 !important;
    font-size: 18px;
    border: none;
    cursor: pointer;
  }
  a {
    position: absolute;
    top: 76%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: #000;
    width: 64%;
    color: white;
    font-size: 18px;
    border: none;
    cursor: pointer;
  }
  .disabled{
    opacity: 1;
    background-color: #9c98c2 !important ;
  }
}

@media (min-width: 576px) {
  .solution-link {
    cursor:auto !important;
    span {
      font-size: 2rem !important;
      cursor:auto !important;

    }
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}

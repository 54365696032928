.form-control.is-valid, .was-validated .form-control:valid {
  border-color: #000;
  background-image: none;
  border-right-color: transparent;
}
.form-control.is-valid:focus, .was-validated .form-control:valid:focus {
  border-color: inherit;
  box-shadow: none;
}
.input-group-text{
  border-color: #000;
}
.form-control.is-invalid, .was-validated .form-control:invalid {
  background-image: none;
  border-right-color: transparent;
}

.form-control.is-invalid:focus, .was-validated .form-control:invalid:focus {
  border-color: #dc3545;
  box-shadow: none;
}

.form-control.is-invalid:focus, .was-validated .input-group-text:invalid:focus {
  border-color: #dc3545;
  box-shadow: none;
}
/* .form-control.is-valid:focus, .was-validated .form-control:valid:focus {
  border-color: #000;
  box-shadow: 0 0 0 0.2rem rgba(84, 79, 146, 0.42);
} */

.input-title {
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0.51px;
  text-align: left;
}

.input-container {
  display: flex;
  flex-direction: column;
  gap: 2px;
  position: relative;
}

.input-container span{
  color : #6c6c6c;
  font-size: 12px;
}

.input-style {
  padding: 10px;
  border: 2px solid #E6E6E6;
  font-size: 16px;
  color: #555;
  outline: none;
  width: 170px;
  padding-right: 50px;
  height: 48px;
}

.input-mm{
  position: absolute;
  right: 10px;
  top: 25%;
  transform: translateY(-25%);
}
.input-mm span{
  font-size: 16px;
  color : #000;
}

.input-style:focus {
  border-color: #007bff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}


.input-style.error {
  border-color: red; /* Add your desired error border color */
  box-shadow: 0 0 0 0.2rem rgba(255, 0, 0, 0.25); /* Add your desired error box shadow */
}

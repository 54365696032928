.Refill {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  padding-left: 0;
  padding-right: 0;

  .border-container {
    display: inline-block;
    background-color: #f5f5f5;
    cursor: pointer;
  }

  .border {
    background-position: center; /* Center the image */
    background-size: auto auto;
    margin-left: auto;
    margin-right: auto;
  }

  .main-warpper {
    width: 56px;
    height: 56px;
  }

  .container-filling-color {
    width: fit-content;
    cursor: pointer;
  }

  &.selected-filling {
    border: 2px solid #353434 !important;
  }

  .tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;

    .tooltiptext {
      visibility: hidden;
      width: 120px;
      background-color: black;
      color: #fff;
      text-align: center;
      border-radius: 6px;
      padding: 5px 0;

      /* Position the tooltip */
      position: absolute;
      z-index: 1;
      top: -5px;
      left: 105%;
    }

    &:hover {
      .tooltiptext {
        visibility: visible;
      }
    }
  }
}

.Cart {
  h2 {
    font-size: 1.64rem;
  }
  .btn-group-lg > .btn,
  .btn-lg {
    line-height: 4.5;
  }

  .color-content {
    width: 3.2rem;
    height: 3.2rem;
    margin: auto;
    margin-top: 1rem;
    background-color: #000;
  }

  .list-group-item {
    background-color: transparent;
  }
  .cart-doors-products {
    padding-right: 0px !important;
    margin-bottom: 1rem;
    margin-top: 0.8rem;
    height: 25vh;
    max-width: 100%;
    overflow-y: auto;
  }
  .cart-doors-products::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: white;
  }

  .cart-doors-products::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px white;
  }
  .cart-doors-products::-webkit-scrollbar-thumb {
    background-color: #000;
    outline: 1px solid white;
  }
  .MuiOutlinedInput-input {
    padding: 16px 14px;
  }

  // Small devices (landscape phones, 576px and up)
  @media (min-width: 576px) {
    .color-content {
      width: 3.6rem;
      height: 3.6rem;
    }
    .cart-doors-products {
      height: 28vh;
    }
  }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {
    h2 {
      font-size: 1.64rem;
    }
    .color-content {
      width: 3.6rem;
      height: 3.6rem;
    }
    .cart-doors-products {
      height: 28vh;
    }
  }

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) {
    h2 {
      font-size: 2rem;
    }
    .color-content {
      width: 4.8rem;
      height: 4.8rem;
    }
    .cart-doors-products {
      height: 28vh;
      margin-bottom: 1.8rem;
    }
  }
}

.list-group-item {
  border: none !important;
}

.table td {
  border: 0;
}

.table td:first-child {
  text-align: left;
}

.table td:last-child {
  text-align: right;
}

.font-weight-bold {
  font-weight: 900 !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-variant: normal !important;
  color: #707070;
}

.text-bold { 
  font-weight: bolder !important;
  font-size: 18px !important;
  font-style: normal !important;
  font-variant: normal !important;
}
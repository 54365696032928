.terms-and-condition-wrapper {
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    overflow-y: scroll;
}

.terms-and-condition-wrapper > h3 {
    text-align: center;
}

.terms-and-condition-wrapper  > div{
    margin-top: 20px;
}

.modal-button-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
}

.modal-button-wrapper > button:hover {
    font-weight: bold;
}

.modal-content {
    border-radius: 40px !important;
}
.form-control:focus {
  color: black;
}

.add-conatiner {
  grid-area: div1;
  align-self: center;
  justify-self: center;
}

.arrow-conatiner {
  grid-area: div2;
  align-self: center;
  justify-self: center;
}

.text-distance {
  grid-area: dimention;
  align-self: center;
  justify-self: center;
}

.section-data {
  grid-area: section;
  align-self: center;
  justify-self: center;
  padding-bottom: 1px;
}

/* .text-mm{
  grid-area: 
  align-self: center;
  justify-self: center;
} */

.preview-hinge-vertical-right {
  width: 100%;
  height: calc(492px + 1rem);
  display: grid;
  /* The sum of height central is 200px */
  grid-template-rows: 0px 0px 0px 48px 48px 48px 48px 48px 0px 0px 0px;
  /* The sum of width central is 150px */
  grid-template-columns: 0px 0px 0px 36px 36px 36px 36px 36px 7rem 15px 25px;
  grid-template-areas:
    ". . . . . . . . . . ."
    ". . . . . . . . . . ."
    ". . . . . . . . . . ."
    ". . . preview preview preview preview preview . . ."
    ". . . preview preview preview preview preview . . ."
    ". . . preview preview preview preview preview . . ."
    ". . . preview preview preview preview preview . . ."
    ". . . preview preview preview preview preview . . ."
    ". . . . . . . . . . ."
    ". . . . . . . . . . ."
    ". . . . . . . . . . .";
}

.preview-hinge-vertical-left {
  width: 100%;
  height: calc(492px + 1rem);
  display: grid;
  /* The sum of height central is 200px */
  grid-template-rows: 0px 0px 0px 48px 48px 48px 48px 48px 0px 0px 0px;
  /* The sum of width central is 150px  7rem 15px 25px*/
  grid-template-columns: 25px 15px 7rem 36px 36px 36px 36px 36px 0rem 0px 0px;
  grid-template-areas:
    ". . . . . . . . . . ."
    ". . . . . . . . . . ."
    ". . . . . . . . . . ."
    ". . . preview preview preview preview preview . . ."
    ". . . preview preview preview preview preview . . ."
    ". . . preview preview preview preview preview . . ."
    ". . . preview preview preview preview preview . . ."
    ". . . preview preview preview preview preview . . ."
    ". . . . . . . . . . ."
    ". . . . . . . . . . ."
    ". . . . . . . . . . .";
}
.preview-hinge-horizontal-top {
  height: 100%;
  width: 100%;
  display: grid;
  /* The sum of height central is 200px */
  grid-template-columns: 0px 0px 0px 48px 48px 48px 48px 48px 0px 0px 0px;
  /* The sum of width central is 150px */
  grid-template-rows: 0px 0px 0px 36px 36px 36px 36px 36px 7rem 15px 25px;
  grid-template-areas:
    ". . . . . . . . . . ."
    ". . . . . . . . . . ."
    ". . . . . . . . . . ."
    ". . . preview preview preview preview preview . . ."
    ". . . preview preview preview preview preview . . ."
    ". . . preview preview preview preview preview . . ."
    ". . . preview preview preview preview preview . . ."
    ". . . preview preview preview preview preview . . ."
    ". . . . . . . . . . ."
    ". . . . . . . . . . ."
    ". . . . . . . . . . .";
}

.preview-hinge-horizontal-bottom {
  height: 100%;
  width: 100%;
  display: grid;
  /* The sum of height central is 200px */
  grid-template-columns: 0px 0px 0px 48px 48px 48px 48px 48px 0px 0px 0px;
  /* The sum of width central is 150px */
  grid-template-rows: 0px 0px 0px 36px 36px 36px 36px 36px 7rem 15px 25px;
  grid-template-areas:
    ". . . . . . . . . . ."
    ". . . . . . . . . . ."
    ". . . . . . . . . . ."
    ". . . preview preview preview preview preview . . ."
    ". . . preview preview preview preview preview . . ."
    ". . . preview preview preview preview preview . . ."
    ". . . preview preview preview preview preview . . ."
    ". . . preview preview preview preview preview . . ."
    ". . . . . . . . . . ."
    ". . . . . . . . . . ."
    ". . . . . . . . . . .";
}
.preview-hinge-horizontal-slider
{
  height: 100%;
  width: 100%;
  display: grid;
  /* The sum ofheight central is 200px */
  grid-template-columns: 0px 0px 0px 48px 48px 48px 48px 48px 0px 0px 0px;
  /* The sum of width central is 150px */
  grid-template-rows: 25px 15px 7rem 36px 36px 36px 36px 36px 0px 0px 0px;
  grid-template-areas:
    ". . . . . . . . . . ."
    ". . . . . . . . . . ."
    ". . . . . . . . . . ."
    ". . . preview preview preview preview preview . . ."
    ". . . preview preview preview preview preview . . ."
    ". . . preview preview preview preview preview . . ."
    ". . . preview preview preview preview preview . . ."
    ". . . preview preview preview preview preview . . ."
    ". . . . . . . . . . ."
    ". . . . . . . . . . ."
    ". . . . . . . . . . .";
}

.preview-hinge-normal-right {
  height: 100%;
  width: 100%;
  display: grid;
  /* The sum of width central is 209px */
  grid-template-columns: 0px 0px 0px 40px 40px 40px 40px 40px 6rem 15px 44px;
  /* The sum of height central is 299px */
  grid-template-rows: 0px 0px 0px 40px 40px 40px 40px 40px 0px 0px 0px;
  grid-template-areas:
    ". . . . . . . . . . ."
    ". . . . . . . . . . ."
    ". . . . . . . . . . ."
    ". . . preview preview preview preview preview . . ."
    ". . . preview preview preview preview preview . . ."
    ". . . preview preview preview preview preview . . ."
    ". . . preview preview preview preview preview . . ."
    ". . . preview preview preview preview preview . . ."
    ". . . . . . . . . . ."
    ". . . . . . . . . . ."
    ". . . . . . . . . . .";
}

.preview-hinge-normal-left {
  height: 100%;
  width: 100%;
  display: grid;
  /* The sum of width central is 209px */
  grid-template-columns: 44px 15px 6rem 40px 40px 40px 40px 40px 0px 0px 0px;
  /* The sum of height central is 299px */
  grid-template-rows: 0px 0px 0px 40px 40px 40px 40px 40px 0px 0px 0px;
  grid-template-areas:
    ". . . . . . . . . . ."
    ". . . . . . . . . . ."
    ". . . . . . . . . . ."
    ". . . preview preview preview preview preview . . ."
    ". . . preview preview preview preview preview . . ."
    ". . . preview preview preview preview preview . . ."
    ". . . preview preview preview preview preview . . ."
    ". . . preview preview preview preview preview . . ."
    ". . . . . . . . . . ."
    ". . . . . . . . . . ."
    ". . . . . . . . . . .";
}
.preview-hinge-normal-bottom {
  height: 100%;
  width: 100%;
  display: grid;
  /* The sum of width central is 209px */
  grid-template-columns: 0px 0px 0px 40px 40px 40px 40px 40px 0px 0px 0px;
  /* The sum of height central is 299px */
  grid-template-rows: 0px 0px 0px 40px 40px 40px 40px 40px 6rem 15px 44px;
  grid-template-areas:
    ". . . . . . . . . . ."
    ". . . . . . . . . . ."
    ". . . . . . . . . . ."
    ". . . preview preview preview preview preview . . ."
    ". . . preview preview preview preview preview . . ."
    ". . . preview preview preview preview preview . . ."
    ". . . preview preview preview preview preview . . ."
    ". . . preview preview preview preview preview . . ."
    ". . . . . . . . . . ."
    ". . . . . . . . . . ."
    ". . . . . . . . . . .";
}
.preview-hinge-normal-top {
  height: 100%;
  width: 100%;
  display: grid;
  /* The sum of width central is 209px */
  grid-template-columns: 0px 0px 0px 40px 40px 40px 40px 40px 0px 0px 0px;
  /* The sum of height central es 299px */
  grid-template-rows: 44px 15px 6rem 40px 40px 40px 40px 40px 0px 0px 0px;
  grid-template-areas:
    ". . . . . . . . . . ."
    ". . . . . . . . . . ."
    ". . . . . . . . . . ."
    ". . . preview preview preview preview preview . . ."
    ". . . preview preview preview preview preview . . ."
    ". . . preview preview preview preview preview . . ."
    ". . . preview preview preview preview preview . . ."
    ". . . preview preview preview preview preview . . ."
    ". . . . . . . . . . ."
    ". . . . . . . . . . ."
    ". . . . . . . . . . .";
}
.hinge-Left {
  display: grid;
  grid-template-columns: 35% 35% 10% 20%;
  grid-template-areas: "dimention div1 section div2";
}

.hinge-Top {
  display: grid;
  grid-template-rows: 35% 35% 10% 20%;
  grid-template-areas:
    "dimention"
    "div1"
    "section"
    "div2";
}

.hinge-Bottom {
  display: grid;
  grid-template-rows: 25% 15% 30% 30%;
  grid-template-areas:
    "div2"
    "section"
    "div1"
    "dimention";
}

.hinge-Right {
  display: grid;
  grid-template-columns: 20% 15% 30% 35%;
  grid-template-areas: "div2 section div1 dimention";
}

.text-black {
  font-size: 0.8rem;
  color: black;
}

.grid-hinge-container {
  z-index: 5;
  height: 100%;
}
.form-control{
  border-radius: 0;
}

.form-control.is-valid:focus,
.was-validated .form-control:valid:focus {
  border-color: #000 !important;
  box-shadow: 0 0 0 0.2rem rgba(84, 79, 146, 0.5) !important;
}

.form-control.is-valid,
.was-validated .form-control:valid {
  border-color: #000 !important;
}

.form-control:valid {
  background-image: none !important;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  background-image: none;
}

.arrow-see-vertical {
  background-color: black;
  width: 1.8px;
  opacity: 0.2;
  height: 100%;
}

.arrow-see-horizontal {
  background-color: black;
  height: 1.8px;
  opacity: 0.2;
  width: 100%;
}

.border-box-Top {
  border-top: 2px solid #00000029 !important;
}

.border-box-Bottom {
  border-bottom: 2px solid #00000029 !important;
}

.border-box-Right {
  border-right: 2px solid #00000029 !important;
}

.border-box-Left {
  border-left: 2px solid #00000029 !important;
}

.input-size-position {
  width: 33px;
  font-size: 0.65rem !important;
}

@media only screen and (min-width: 768px) {
  .preview-hinge-vertical-right {
    grid-template-rows: 0px 0px 0px 65px 65px 65px 65px 65px 0px 0px 0px;
    grid-template-columns: 0px 0px 0px 55px 55px 55px 55px 55px 7rem 15px 25px;
  }
  .preview-hinge-vertical-left {
    grid-template-rows: 0px 0px 0px 65px 65px 65px 65px 65px 0px 0px 0px;
    grid-template-columns: 25px 15px 7rem 55px 55px 55px 55px 55px 0rem 0px 0px;
  }
  .preview-hinge-horizontal-bottom {
    grid-template-columns: 0px 0px 0px 65px 65px 65px 65px 65px 0px 0px 0px;
    grid-template-rows: 0px 0px 0px 55px 55px 55px 55px 55px 7rem 15px 25px;
  }
  .preview-hinge-horizontal-top {
    grid-template-columns: 0px 0px 0rem 65px 65px 65px 65px 65px 0px 0px 0px;
    grid-template-rows: 25px 15px 7rem 55px 55px 55px 55px 55px 0rem 0px 0px;
  }

  .preview-hinge-normal-right {
    grid-template-columns: 0px 0px 0px 60px 60px 60px 60px 60px 6rem 15px 44px;
    grid-template-rows: 0px 0px 0px 60px 60px 60px 60px 60px 0px 0px 0px;
  }
  .preview-hinge-normal-left {
    grid-template-columns: 44px 15px 6rem 60px 60px 60px 60px 60px 0px 0px 0px;
    grid-template-rows: 0px 0px 0px 60px 60px 60px 60px 60px 0px 0px 0px;
  }
  .preview-hinge-normal-bottom {
    grid-template-columns: 0px 0px 0px 60px 60px 60px 60px 60px 0px 0px 0px;
    grid-template-rows: 0px 0px 0px 60px 60px 60px 60px 60px 6rem 15px 44px;
  }
  .preview-hinge-normal-top {
    grid-template-columns: 0px 0px 0px 60px 60px 60px 60px 60px 0px 0px 0px;
    grid-template-rows: 44px 15px 6rem 60px 60px 60px 60px 60px 0px 0px 0px;
  }

  .preview-vertical-hinge {
    width: calc(404px + 7rem) !important;
    height: calc(492px + 7rem) !important;
    /* The sum of height central is 299px */
    grid-template-rows: 25px 15px 7rem 65px 65px 65px 65px 65px 7rem 15px 25px !important;
    /* The sum of width central is 209px */
    grid-template-columns: 25px 15px 7rem 55px 55px 55px 55px 55px 7rem 15px 25px !important;
  }

  .preview-horizontal-hinge {
    height: calc(404px + 7rem) !important;
    width: calc(492px + 7rem) !important;
    /* The sum of height central is 299px */
    grid-template-columns: 25px 15px 7rem 65px 65px 65px 65px 65px 7rem 15px 25px !important;
    /* The sum of width central is 209px */
    grid-template-rows: 25px 15px 7rem 55px 55px 55px 55px 55px 7rem 15px 25px !important;
  }

  .preview-normal-hinge {
    height: calc(441px + 6.6rem) !important;
    width: calc(441px + 6.6rem) !important;
    /* The sum of width central is 209px */
    grid-template-columns: 44px 15px 6rem 60px 60px 60px 60px 60px 6rem 15px 44px !important;
    /* The sum of height central is 299px */
    grid-template-rows: 44px 15px 6rem 60px 60px 60px 60px 60px 6rem 15px 44px !important;
  }
}

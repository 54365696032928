.Filling {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  padding-left: 0;
  padding-right: 0;
  gap : 10px;
  .border-container {
    display: inline-block;
    background-color: #f5f5f5;
    cursor: pointer;
  }

  .border {
    background-position: center; /* Center the image */
    background-size: auto auto;
    margin-left: auto;
    margin-right: auto;
  }

  .main-warpper {
    width: 48px;
    height: 48px;
  }
}

.container-filling-color {
  width: fit-content;
  cursor: pointer;
}

.selected-filling {
  border: 2.4px solid #353434 !important;
}

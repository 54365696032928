.home-border {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .home-steps {
    margin-top: 2.4%;
    p {
      font-size: 1.36rem;
      margin-bottom: 2%;
    }
  }
}
.home-border::after {
  content: "";
  background: url("../../img/home_background.jpeg") no-repeat fixed center;
  background-size: cover;
  opacity: 0.5;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;
}
.home-title {
  margin-top: 10vh;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: start;
  h1 {
    margin-bottom: 0px;
    height: 100%;
    margin-left: 2rem;
    font-size: 3rem;
    font-family: 'Muli';
  }
  span {
    font-size: 2rem;
  }
}
.home-steps {
  p {
    font-size: 1.6rem !important;
    font-family: "Open Sans",  sans-serif;
  }
}
.home-steps ol {
  align-items: center;
  padding-left: 0em;
  list-style: none;
  counter-reset: li;
}
.home-steps li {
  width: 100%;
  font-size: 1.36rem;
  padding: 0.48% 0rem;
}
.home-steps li::before {
  content: counter(li);
  background-color: #000000;
  color: white;
  text-align: center;
  border-radius: 50%;
  display: inline-block;
  font-size: 1.6rem;
  font-weight: 600;
  width: 2.4rem;
  margin-right: 1rem;
}
.home-steps ol li {
  counter-increment: li;
  font-family: "Open Sans", sans-serif;
}

.home-footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 12.4%;
  button {
    width: 24%;
    height: 4%;
    padding-top: 0.64%;
    padding-bottom: 0.64%;
    font-size: 1.24rem;
  }
}

@media only screen and (max-height: 880px) {
  .home-steps li {
    font-size: 1.24rem;
    padding: 0.36% 0rem;
  }
  .logo {
    width: 64px;
    height: 64px;
  }
  .home-steps li::before {
    content: counter(li);
    font-size: 1.16rem;
    width: 1.64rem;
    margin-right: 1rem;
  }
  .home-footer {
    button {
      margin-bottom: 0.64%;
      margin-left: auto;
      width: 12rem;
      font-size: 1.24rem;
    }
  }
}

@media only screen and (max-width: 768px) {
  .home-title {
    margin-top: 10vh;
    h1 {
      margin-left: 1rem;
      font-size: 2.6rem;
    }
  }
  .home-steps span {
    font-size: 2.16rem;
  }

  .home-steps p {
    font-size: 1.36rem !important;
  }
  .home-steps li::before {
    font-size: 1.12rem;
    width: 1.64rem;
  }
  .footer {
    height: 4rem;
    margin-top: 4%;
  }
}

@media only screen and (max-width: 576px) {
  .home-title {
    h1 {
      margin-left: 0.36rem;
      font-size: 2.48rem;
    }
  }
  .home-steps {
    p {
      font-size: 1.24rem !important;
      margin-bottom: 1rem;
    }
  }
  .home-steps li {
    font-size: 1.12rem;
  }
  .home-steps li::before {
    font-size: 1.12rem;
    width: 1.64rem;
  }
}

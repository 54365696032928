.preview-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.preview-window {
  display: grid;
  grid-template-rows: 100%;
  grid-template-columns: 100%;
  grid-template-areas: "principal-preview";
}

.principal-preview {
  grid-area: principal-preview;
  width: 100%;
  height: 100%;
}

.img-preview {
  grid-area: principal-preview;
  height: 100%;
  width: 100%;
}

.img-wine-glass {
  height: 100%;
  width: 100%;
}

.img-preview-grid {
  grid-area: preview;
  width: 100%;
  height: 100%;
}
.no-hinge-mech {
  max-height: 400px;
}

@media only screen and (min-width: 768px) {
  .preview-vertical {
    width: 210px !important;
    height: 300px !important;
  }

  .preview-horizontal {
    height: 210px !important;
    width: 300px !important;
  }

  .preview-normal {
    height: 250px !important;
    width: 250px !important;
  }

  .img-wine {
    width: 175px !important;
  }
}

.preview-vertical {
  width: 150px;
  height: 200px;
}

.preview-horizontal {
  height: 150px;
  width: 200px;
}

.preview-normal {
  height: 150px;
  width: 150px;
}

.img-wine {
  width: 100px;
}

.filling-container {
  opacity: 0.48;
  z-index: 1;
  display: -moz-inline-grid;
  justify-content: center;
}

.mechanized {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.mechanized img{
  margin: auto;
  width: 250px;
}
.mechanized p{
  font-size: 16px !important;
  font-variant: normal;
  font-style: normal;
  text-align: left;
}
/* .opacity-30{
  opacity: 0.3;
} */

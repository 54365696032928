.prevButton,
.nextButton
{
    font-size: 16px;
    font-weight: lighter;
    letter-spacing: 0.45px;
    color: #fff;
    text-transform: uppercase;
    cursor: pointer;

    display: flex;
    gap: 4px;
    align-items: center;
}

.prevButton:hover {
    font-weight: bolder;
}

.nextButton:hover { 
    font-weight: bolder;
}

.sidebar {
    box-shadow: 0px 3px 6px #00000029;
}

.sidebar-button:hover {
  font-weight: bold !important;
}
.previewBack {
  /* background-image: url('../img/wall.jpg'); */
  background-color: #ffffff;
  height: 100%;
  width: 100%;
}
.row {
  margin: 0px;
}

@media only screen and (min-width: 760px) {
  .col-preview {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .col-preview h4 {
    width: 100%;
    height: 3.6rem;
  }
  .open-door-container {
    max-height: 6rem;
  }
}

.border-right-color-white {
  border-right-color: white;
}

.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(84, 79, 146, 0.51);
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.fillings-container {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding-top: 1rem;
}

.add-cursor-pointer {
  cursor: pointer;
}

.bg-primary {
  background-color: #706f6f !important;
  height: 3.2rem;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 1.6rem;
}

.float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #000;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  box-shadow: 2px 2px 3px #ececec;
  align-items: center;
  z-index: 101;
}

.body-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  height: calc(100vh - 140px);
  max-height: calc(100vh - 140px);
}

.preview-leyend {
  width: 100%;
  text-align: center;
  color: #000000;
}

.preview-title {
  width: 100%;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.cambiar {
  font-size: 12px;
  color: #707070;
  font: normal normal normal;
  z-index: 999;
}

.cambiar:hover {
  text-decoration: underline;
}

.result-text {
  font: normal normal normal 14px/24px 'Muli';
  color: #707070;
  font-size: 14px;
}

.result-title {
  font: normal normal bold 16px/20px 'Muli';
  letter-spacing: 0.38px;
  color: #000000;
}

.accordion-button {
  padding: 10px 0px;
}

.accordion-button::after {
}

.accordion-button:not(.collapsed) {
  background: none !important;
  box-shadow: none !important;
}

.accordion-button:focus {
  z-index: 3;
  border-color: none !important;
  outline: 0;
  box-shadow: none !important;
}

.accordion-item {
  border: none !important;
}
.selected-color {
  width: 100%;
  height: 100%;
}

.selected-height {
  height: 6rem !important;
  margin-bottom: 0.5rem;
}

.max-width-open-selector {
  max-width: 8rem;
}

.border-primary {
  border-color: #000 !important;
}

.selected-box {
  display: flex;
  flex-wrap: wrap;
  margin: 2rem 0rem;
}
.step-box {
  margin-top: 1rem;
  align-self: center;
  width: 100%;
  height: 112px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  a {
    width: 100%;
    height: 100%;
    text-align: center;
    #step-text-box {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      border: 2px solid rgb(206, 206, 206);
    }
    span {
      color: #373d44;
      line-height: 15px;

      font-size: 16px;
    }
    p {
      font-size: 14px;
    }
  }
}
@media screen and (max-width: 1366px) {
  .step-box-breadcumbs {
    width: 96px !important;
  }
}
.step-box-breadcumbs {
  align-self: center;
  display: flex;
  align-items: center;
  width: 120px;
  height: 88px;
  margin-right: 1rem;
  a {
    width: 100%;
    height: 100%;
    text-align: center;
    padding-top: 0.48rem;
    #step-text-box {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      border: 2px solid rgb(206, 206, 206);
      .hinge-text{
        font-size: 12px !important;
      }
    }
    span {
      color: #373d44;
      font-size: 10px;
      width: 100%;
    }
  }
}
.breadcrumb-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
}

.filling-box-breadcrumbs {
  border-radius: 100%;
  background-position: center; /* Center the image */
  background-size: auto auto;
}
.filling-box-breadcrumbs img {
  height: 48px;
  width: 48px;
  border-radius: 100%;
}
.step-number {
  font-size: 1.2rem;
  align-self: flex-start;
  font-weight: 700;
  color: #000;
  margin-right: 0.4rem;
}
.step-number-rounded {
  font-size: 1.16rem;
  align-self: flex-start;
  font-weight: 700;
  color: white;
  border-radius: 100%;
  width: 1.6rem;
  padding: 0.96rem 0.96rem;
  height: 1.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000;
  margin-right: 0.4rem;
}
.profile-container-bread {
  display: flex;
  flex-direction: column;
  align-items: center;
}

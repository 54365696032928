.door-opened {
  border-color: transparent;
  width: 100%;
  border-style: solid;
  border-width: 1.5px;
  cursor: pointer;
}

.arrow-right {
  width: 0;
  height: 0;
  border-top: 3.8rem solid transparent;
  border-bottom: 3.9rem solid transparent;
  border-left: 38px solid #e0e0e0;
}

.arrow-style {
  opacity: 0.7;
  max-width: 112px;
}

.rotate {
  transform: rotate(180deg);
}

.max-height {
  height: 100% !important;
}

.selected {
  /* border-color: #000; */
  border-color: transparent;
  border-width: 5px;
}

.direction-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
  img {
    max-width: 65px;
    max-height: 65px;
  }
  small {
    color: #000000;
    font-size: 16px;
  }
}
